import React from "react";
import { Button } from "@mui/material";

export default function MyButton(props) {
  return (
    <Button onClick={props.onClick} sx={buttonStyle}>
      {props.children}
    </Button>
  );
}


const buttonStyle = {
  color: "#fff",
  background: 'linear-gradient(90deg, #324193 0%, #325BA7 8%, #3177BB 18%, #318ACA 29%, #3196D3 39%, #319AD6 50%, #3194D1 59%, #3182C4 71%, #3265AE 86%, #324193 100%);',
  fontFamily: "bahij",
  borderRadius: 3,
  padding: "7px 20px",
  fontSize: "19px",
  margin: "5px 10px",
  width: "90%",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#000",
    boxShadow: "none",
  },
};
